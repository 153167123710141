@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-spin .ant-spin-dot-item{
  background-color: #242424;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-radio-button-wrapper-checked:not(:where(.css-dev-only-do-not-override-sk7ap8).ant-radio-button-wrapper-disabled),:where(.css-dev-only-do-not-override-sk7ap8).ant-radio-button-wrapper-checked:not(:where(.css-dev-only-do-not-override-sk7ap8).ant-radio-button-wrapper-disabled):first-child{
    color:#FFAF00;
    border-color:#FFAF00
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-select-single .ant-select-selector,:where(.css-dev-only-do-not-override-sk7ap8).ant-select .ant-select-selection-placeholder{
    color: #757575;
}
.ant-select-item-option-content {
  
  white-space: wrap !important;
  
}
/* Dashed Line */
.dashed-line{
    height: 1px;
    background-image: linear-gradient(to right, #C2C2C2 80%, rgba(255, 255, 255, 0) 20%);
    background-position: top;
    background-size: 30px 1px;
    background-repeat: repeat-x;
}

/* Google map style */
.pac-container{
    z-index: 10000;
}

