// Table Style here.

// :where(.css-dev-only-do-not-override-sk7ap8).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-sk7ap8).ant-table-wrapper .ant-table-thead >tr>td{
//     background-color: rgba(255, 175, 0, 0.47) !important;
//     padding: 30px !important;
// }
.ant-table-wrapper .ant-table{
    border-radius: 16px 16px 0 0;
}
thead[class*="ant-table-thead"] th,thead[class*="ant-table-thead"] td{
    background-color: rgba(255, 175, 0, 0.47) !important;
    padding: 1.25rem !important;
    text-align: center  !important;
}
thead[class*="ant-table-thead"] tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, thead[class*="ant-table-thead"] tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    width: 0 !important;
}
thead[class*="ant-table-thead"] tr:first-child >*:first-child{
    border-start-start-radius: 16px !important;
}
// :where(.css-dev-only-do-not-override-sk7ap8).ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child{
//     border-start-end-radius: 16px !important;
// }
thead[class*="ant-table-thead"] tr:first-child >*:last-child{
    border-start-end-radius: 16px !important;
}
tbody[class*="ant-table-tbody"] tr>td{
    // padding-left: 30px !important;
    cursor: pointer;
    min-width: 6rem !important;
    text-align: center !important;
}
tbody[class*="ant-table-tbody"] tr.ant-table-placeholder>td{
    padding-left: 0px !important;
}

// Pagination Style here.
ul[class*="ant-pagination"]{
    margin: 16px !important;
}
ul[class*="ant-pagination"] > .ant-pagination-item-active {
    font-weight: 600 !important;
    background-color: #ffffff !important;
    border-color: #ffaf00 !important;
    a{
        color: #ffaf00 !important;
    }
    &:hover{
        border-color: #242424 !important;
        a{
            color: #242424 !important;
        }
    }
}
ul[class*="ant-pagination"] > .ant-pagination-item:not(.ant-pagination-item-active):hover {
    transition: all 0.2s !important;
    background-color: #242424 !important;
    a{
        color: #ffffff !important;
    }
}
ul[class*="ant-pagination"] > .ant-pagination-prev:hover .ant-pagination-item-link, ul[class*="ant-pagination"] > .ant-pagination-next:hover .ant-pagination-item-link{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #242424 !important;
    svg{
        color: #ffffff !important;
    }
}
ul[class*="ant-pagination"] > .ant-pagination-prev .ant-pagination-item-link, ul[class*="ant-pagination"] > .ant-pagination-next .ant-pagination-item-link{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

//Filter style
.ant-table-filter-dropdown-btns > .ant-btn-primary {
    background-color: #ffaf00;
    &:hover{
        background-color: #242424;
    }
}

// TextArea Style here
.ant-input-textarea textarea{
    border: 1px solid #242424;
    padding: 15px;
    &:hover,&:focus{
        border-color: #242424;
        box-shadow: none;
    }
}

/* Accordin content section */
div[class*="ant-collapse"] .ant-collapse-content-box{
    padding: 0 !important;
}
div[class*="ant-collapse"] .ant-collapse-content{
    border: none !important;
    border-radius: 0 !important;
}
div[class*="ant-collapse"] .ant-collapse-item:last-child >.ant-collapse-content{
    border-radius: 0 !important;
}

// Tab content style


.ant-tabs-tab{
    &:hover{
        background-color: #242424 !important;
        .ant-tabs-tab-btn{
            color: #ffffff !important;
        }
    }
    .ant-tabs-tab-btn{
        color: #242424 !important;
        &:hover{
            color: #ffffff !important;
        }
    }
}
.ant-tabs-tab-active{
    background: #ffaf00 !important;
    &:hover{
        background-color: #242424 !important;
    }
    .ant-tabs-tab-btn{
        color: #ffffff !important;
        &:hover{
            color: #ffffff !important;
        }
    }
}

// Modal styling
.payment-modal{
    .ant-modal-content{
        padding: 0;
    }
}

// Dashboard Styles
#dashboardcomp{
    .MainHeaderTitle{
        :where(.css-dev-only-do-not-override-sk7ap8).ant-radio-button-wrapper-checked:not(:where(.css-dev-only-do-not-override-sk7ap8).ant-radio-button-wrapper-disabled),:where(.css-dev-only-do-not-override-sk7ap8).ant-radio-button-wrapper-checked:not(:where(.css-dev-only-do-not-override-sk7ap8).ant-radio-button-wrapper-disabled):first-child{
            background: #ffff;
            color: #001D41;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            border-color: #DCE1E4;
            &::before{
                background-color: #DCE1E4;
            }
        }
        :where(.css-dev-only-do-not-override-sk7ap8).ant-radio-button-wrapper{
            background: #F2F7FA;
            color: #242424;
            font-size: 16px;
            font-weight: 400;
            &:hover{
                font-weight: 700;
                color: #001D41;
                background: #ffff;
            }
        }
    }
}